import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/pages/home.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/lottery',
      name: 'lottery',
      component: () => import('@/views/pages/lottery.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/pages/about.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/rule',
      name: 'rule',
      component: () => import('@/views/pages/rule.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/check-result',
      name: 'check-result',
      component: () => import('@/views/pages/check-lottery.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/point',
      name: 'point',
      component: () => import('@/views/pages/point.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/deposit',
      name: 'first-deposit',
      component: () => import('@/views/pages/deposit/first-deposit.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/deposit/bank',
      name: 'bank',
      component: () => import('@/views/pages/deposit/bank.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/deposit/qr-code',
      name: 'qr-code',
      component: () => import('@/views/pages/deposit/qr-code.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/withdraw',
      name: 'withdraw',
      component: () => import('@/views/pages/withdraw.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/lottery/cart',
      name: 'cart',
      component: () => import('@/views/pages/cart.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/lottery/order',
      name: 'order',
      component: () => import('@/views/pages/order.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/order-history',
      name: 'order-history',
      component: () => import('@/views/pages/history/history-lottery.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/order-history/lot/:id',
      name: 'order-history-lot',
      component: () => import('@/views/pages/history/history-lot.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/order-history/lot/all/:id',
      name: 'order-history-detail',
      component: () => import('@/views/pages/history/history-detail.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/invite-friend',
      name: 'invite-friend',
      component: () => import('@/views/pages/invite-friend.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/invite-friend/zian',
      name: 'invite-by-zian',
      component: () => import('@/views/pages/invite-by-zian.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/pages/profile.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/lucky-box',
      name: 'lucky-box',
      component: () => import('@/views/pages/lucky-box/box.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/lucky-wheel',
      name: 'lucky-wheel',
      component: () => import('@/views/pages/lucky-wheel/wheel.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/auth/login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/news',
      name: 'news-all',
      component: () => import('@/views/pages/news/all.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/news/:id',
      name: 'news',
      component: () => import('@/views/pages/news/news.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/pages/auth/register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/pages/contact.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reward',
      name: 'reward',
      component: () => import('@/views/pages/reward-all.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/auth/forgot-password.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/re-password/:token',
      name: 're-password',
      component: () => import('@/views/pages/auth/re-password.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
