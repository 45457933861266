import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueSkeletonLoader from 'skeleton-loader-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueQRCodeComponent from 'vue-qrcode-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueMeta from 'vue-meta'

// eslint-disable-next-line import/no-extraneous-dependencies
import loader from 'vue-ui-preloader'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// custom css
import './assets/css/custom.css'
import './assets/css/button.css'
import './assets/css/footer.css'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/clipboard'

import 'vue-select/dist/vue-select.css'

Vue.component('vue-skeleton-loader', VueSkeletonLoader)
Vue.component('qr-code', VueQRCodeComponent)
// BSV Plugin Registration
Vue.use(VueMeta)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(loader)
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
